<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.amount = item.amount;
        itemDetails.rarity = `3`;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;

        switch (item.id) {
            case 623:
                itemDetails.title = $_(`StPatrickChatBubbleTitle`);
                itemDetails.description = $_(`StPatrickChatBubbleDescription`);
                itemDetails.icon = `chat-bubble-stpatrick`;
                break;
            case 2199:
                itemDetails.title = $_(`4thofjulyChatBubbleTitle`);
                itemDetails.description = $_(`4thofjulyChatBubbleDescription`);
                itemDetails.icon = `chat-bubble-4thofjuly`;
                break;
            case 2241:
                itemDetails.title = $_(`WinterChatBubbleTitle`);
                itemDetails.description = $_(`WinterChatBubbleDescription`);
                itemDetails.icon = `chat-bubble-winter`;
                break;
            default:
                itemDetails.title = $_(`DefaultChatBubbleTitle`);
                itemDetails.description = $_(`DefaultChatBubbleDescription`);
                itemDetails.icon = `chat-bubble`;
                break;
        }
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
